<style>
.gradient-border {
  position: relative;
  background: linear-gradient(to right, #3b82f6, #10b981);
}

.gradient-border::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 2px;
  /* Ajusta el grosor del borde */
  background: linear-gradient(to right, #3b82f6, #10b981);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.item--text {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(0deg, #3b82f6 50%, #10b981 50%);

}
</style>

<template>
  <div class="flex flex-col flex-autov items-center h-full w-full relative">
    <!-- <img src="../assets/fondo-nina.png" class="h-screen w-full absolute object-cover z-10" alt=""> -->
    <div class="h-screen pt-4 md:pt-0 w-full z-20 overflow-y-auto overflow-x-hidden">
      <div
        class="flex items-center justify-center rounded-xl w-full h-full sm:px-4 md:px-4 lg:px-20 xl:px-32 px-2 relative">
        <div
          class="w-full h-full md:py-24 flex flex-col items-center justify-start md:justify-center gap-y-4 absolute md:relative md:top-0 px-4 sm:px-32 md:px-4">
          <div
            class="shadow-sombraExterna dark:shadow-sombraExternaDark w-full md:flex-1 md:w-full px-3 sm:px-6 rounded-2xl flex flex-col pb-6">
            <div class="w-full flex items-center justify-end py-4">
              <!-- <img src="../assets/logo-ooxen.png" class="w-16" alt=""> -->

              <div class="text-end">
                <button :disabled="this.isProcessing === true || this.isPlaying === true" @click="resetConversation"
                  class="center gap-2 ml-1 px-2 py-1 rounded-md ">

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
</svg>

                  <!-- <label
                    class="relative inline-block h-8 w-14 cursor-pointer rounded-full bg-gray-300 dark:bg-fondoDark shadow-[inset_2px_2px_5px_#bcbcbc,inset_-2px_-2px_5px_#ffffff,2px_2px_5px_#bcbcbc,-2px_-2px_5px_#ffffff] dark:shadow-[inset_2px_2px_5px_#00000040,inset_-2px_-2px_5px_#D3D3D316,2px_2px_5px_#00000040,-2px_-2px_5px_#D3D3D316] transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-gray-300 dark:has-[:checked]:bg-fondoDark">
                    <input
                      @click="selectedAuto = !selectedAuto, console.log(selectedAuto), toggleAutoRecording(selectedAuto)"
                      class="peer sr-only" id="AcceptConditions" type="checkbox" v-model="selectedAuto" />
                    <span
                      class="absolute inset-y-0 start-0 m-1 peer-checked:m-1 size-6 peer-checked:size-6 rounded-full peer-checked:bg-blue-300 bg-gray-50 ring-[4px] ring-inset ring-gray-50 dark:ring-gray-700 dark:bg-gray-700 peer-checked:ring-[9px] peer-checked:ring-inset peer-checked:ring-gray-50 dark:peer-checked:ring-gray-700 transition-all peer-checked:start-6"></span>
                  </label> -->

                  <!-- <Switch :checked="selectedAuto"
                    :style="!selectedAuto ? { backgroundColor: 'silver' } : { background: 'linear-gradient(45deg, #3b82f6, #10b981)' }" /> -->
                  <p>Reiniciar Conversación</p>
                </button>
              </div>
            </div>
            <!-- max-w-[400px] -->
            <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4 h-full">
              <div
                class="hidden md:block w-full md:h-full md:max-h-full shadow-[3px_3px_5px_#fafafa,_-3px_-3px_5px_#fafafa] dark:shadow-[3px_3px_5px_#D3D3D316,_-3px_-3px_5px_#D3D3D316] rounded-3xl py-3 border-2 border-neutral-200 dark:border-neutral-700 md:flex md:flex-col">
                <!-- <div class="flex items-center gap-2">
                  <div class="h-10 w-10 rounded-full bg-blue-400 center">
                    <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                        d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
                    </svg>
                  </div>

                  <p class="text-white">Resultados</p>
                </div> -->

                <div class="w-full h-full max-h-[360px] overflow-x-auto" ref="chatContainer2">
                  <div v-if="this.conversation.messages && this.conversation.messages.length">
                    <div class="message px-4" :class="{
                      'message-received message-last message-tail':
                        message.role === 'assistant',
                      'message-sent': message.role === 'user',
                    }" v-for="(message, index) in this.conversation.messages" :key="index">
                      <div v-if="message.role === 'user'" class="messageTop">
                        <div class="w-full py-2 flex items-center justify-end">
                          <div :style="dynamicStyles">
                            <div class="message-text ">
                              <!-- <div style="font-size: large" class="centro" v-if="message.role === 'user'">
                              <b>User</b>
                            </div> -->

                              <div style="height: 10px"></div>
                              <div class="text-black dark:text-white" v-if="message.role === 'user'">
                                <p class="text-sm">{{ message.content }}</p>
                              </div>
                            </div>
                            <div style="font-size: small" class="text-gray-500 w-full text-end">
                              {{ shouldDisplayTimestamp(message.timestamp) }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="message.role === 'assistant'">
                        <div class="w-full py-2">
                          <div :style="dynamicStyles">
                            <div class="message-text">

                              <div style="font-size: large" class="" v-if="message.role === 'assistant'">

                                <div style="font-size: large" class="centro">
                                  <b class="text-black dark:text-white">Elsa AI</b>
                                </div>

                                <div style="height: 10px"></div>
                                <div class="text-black dark:text-white">
                                  <p class="text-sm">{{ message.content }}</p>
                                </div>

                                <div class="mt-2">
                                  <div class="flex items-center gap-4">

                                    <button v-if="this.conversation.messages[index].isComplete &&
                                      (!this.conversation.messages[index].feedback ||
                                        !this.conversation.messages[index].feedback ===
                                        'good' ||
                                        this.conversation.messages[index].feedback === 'bad')
                                    " :disabled="this.conversation.messages[index].feedback === 'bad'" fill="clear"
                                      color="medium" size="small"
                                      @click="this.conversation.messages[index].openBadResponse = true">
                                      <svg v-if="!this.conversation.messages[index].feedback"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                        class="w-5 h-5 text-gray-500">
                                        <path
                                          d="M15.73 5.5h1.035A7.465 7.465 0 0 1 18 9.625a7.465 7.465 0 0 1-1.235 4.125h-.148c-.806 0-1.534.446-2.031 1.08a9.04 9.04 0 0 1-2.861 2.4c-.723.384-1.35.956-1.653 1.715a4.499 4.499 0 0 0-.322 1.672v.633A.75.75 0 0 1 9 22a2.25 2.25 0 0 1-2.25-2.25c0-1.152.26-2.243.723-3.218.266-.558-.107-1.282-.725-1.282H3.622c-1.026 0-1.945-.694-2.054-1.715A12.137 12.137 0 0 1 1.5 12.25c0-2.848.992-5.464 2.649-7.521C4.537 4.247 5.136 4 5.754 4H9.77a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23ZM21.669 14.023c.536-1.362.831-2.845.831-4.398 0-1.22-.182-2.398-.52-3.507-.26-.85-1.084-1.368-1.973-1.368H19.1c-.445 0-.72.498-.523.898.591 1.2.924 2.55.924 3.977a8.958 8.958 0 0 1-1.302 4.666c-.245.403.028.959.5.959h1.053c.832 0 1.612-.453 1.918-1.227Z" />
                                      </svg>

                                      <svg v-if="this.conversation.messages[index].feedback === 'bad'
                                      " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                        class="w-5 h-5 text-red-500">
                                        <path
                                          d="M15.73 5.25h1.035A7.465 7.465 0 0118 9.375a7.465 7.465 0 01-1.235 4.125h-.148c-.806 0-1.534.446-2.031 1.08a9.04 9.04 0 01-2.861 2.4c-.723.384-1.35.956-1.653 1.715a4.498 4.498 0 00-.322 1.672V21a.75.75 0 01-.75.75 2.25 2.25 0 01-2.25-2.25c0-1.152.26-2.243.723-3.218C7.74 15.724 7.366 15 6.748 15H3.622c-1.026 0-1.945-.694-2.054-1.715A12.134 12.134 0 011.5 12c0-2.848.992-5.464 2.649-7.521.388-.482.987-.729 1.605-.729H9.77a4.5 4.5 0 011.423.23l3.114 1.04a4.5 4.5 0 001.423.23zM21.669 13.773c.536-1.362.831-2.845.831-4.398 0-1.22-.182-2.398-.52-3.507-.26-.85-1.084-1.368-1.973-1.368H19.1c-.445 0-.72.498-.523.898.591 1.2.924 2.55.924 3.977a8.959 8.959 0 01-1.302 4.666c-.245.403.028.959.5.959h1.053c.832 0 1.612-.453 1.918-1.227z" />
                                      </svg>
                                    </button>

                                    <TransitionRoot as="template"
                                      :show="this.conversation.messages[index].openBadResponse === true">
                                      <Dialog as="div" class="relative z-50"
                                        @close="this.conversation.messages[index].openBadResponse = false">
                                        <TransitionChild as="template" enter="ease-out duration-300"
                                          enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200"
                                          leave-from="opacity-100" leave-to="opacity-0">
                                          <div class="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
                                        </TransitionChild>

                                        <div class="fixed inset-0 z-10 overflow-y-auto" style="width: 100%;">
                                          <div
                                            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                            <TransitionChild as="template" enter="ease-out duration-300"
                                              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                              enter-to="opacity-100 translate-y-0 sm:scale-100"
                                              leave="ease-in duration-200"
                                              leave-from="opacity-100 translate-y-0 sm:scale-100"
                                              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                                              <DialogPanel
                                                class="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-900 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
                                                <div>
                                                  <div
                                                    class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                      fill="currentColor" class="w-8 h-8 text-white">
                                                      <path
                                                        d="M15.73 5.5h1.035A7.465 7.465 0 0 1 18 9.625a7.465 7.465 0 0 1-1.235 4.125h-.148c-.806 0-1.534.446-2.031 1.08a9.04 9.04 0 0 1-2.861 2.4c-.723.384-1.35.956-1.653 1.715a4.499 4.499 0 0 0-.322 1.672v.633A.75.75 0 0 1 9 22a2.25 2.25 0 0 1-2.25-2.25c0-1.152.26-2.243.723-3.218.266-.558-.107-1.282-.725-1.282H3.622c-1.026 0-1.945-.694-2.054-1.715A12.137 12.137 0 0 1 1.5 12.25c0-2.848.992-5.464 2.649-7.521C4.537 4.247 5.136 4 5.754 4H9.77a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23ZM21.669 14.023c.536-1.362.831-2.845.831-4.398 0-1.22-.182-2.398-.52-3.507-.26-.85-1.084-1.368-1.973-1.368H19.1c-.445 0-.72.498-.523.898.591 1.2.924 2.55.924 3.977a8.958 8.958 0 0 1-1.302 4.666c-.245.403.028.959.5.959h1.053c.832 0 1.612-.453 1.918-1.227Z" />
                                                    </svg>

                                                  </div>
                                                  <div class="mt-3 text-center sm:mt-5">
                                                    <DialogTitle as="h3"
                                                      class="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                                                      Mala Respuesta
                                                    </DialogTitle>
                                                    <div class="mt-2">
                                                      <div class="center w-full mb-2">
                                                        <div class="w-full">
                                                          <textarea v-model="badresponse" rows="6"
                                                            class="w-full
                                                              rounded-md border border-gray-300 dark:border-gray-800
                                                              border-solid resize-none focus:border-gray-300 focus:outline-none focus:ring-0 focus:ring-none focus:ring-offset-0
                                                              text-black dark:text-white bg-white dark:bg-black dark:bg-opacicus:ring-0"
                                                            style="min-height: 100px;"
                                                            placeholder="¿Cuál fué el problema con esta respuesta?">
                                                      </textarea>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                  <button type="button"
                                                    class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-0 focus:ring-none focus:ring-offset-0 sm:col-start-2 sm:text-sm"
                                                    @click="enviarBadResponse(index)">
                                                    Enviar
                                                  </button>
                                                  <button type="button"
                                                    class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-none focus:ring-offset-0 sm:col-start-1 sm:mt-0 sm:text-sm"
                                                    @click="this.conversation.messages[index].openBadResponse = false"
                                                    ref="cancelButtonRef">
                                                    Cerrar
                                                  </button>
                                                </div>
                                              </DialogPanel>
                                            </TransitionChild>
                                          </div>
                                        </div>
                                      </Dialog>
                                    </TransitionRoot>

                                    <!-- <button :disabled="isBotonDesactivado"
                                    v-if="this.conversation.messages[index].isComplete" @click="shareMessage(index)"
                                    size="small" fill="clear" color="medium">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                      class="size-5 text-gray-300">
                                      <path fill-rule="evenodd"
                                        d="M15.75 4.5a3 3 0 1 1 .825 2.066l-8.421 4.679a3.002 3.002 0 0 1 0 1.51l8.421 4.679a3 3 0 1 1-.729 1.31l-8.421-4.678a3 3 0 1 1 0-4.132l8.421-4.679a3 3 0 0 1-.096-.755Z"
                                        clip-rule="evenodd" />
                                    </svg>
                                  </button> -->

                                    <button v-if="this.conversation.messages[index].isComplete"
                                      @click="Copy(index), openNotification(true, index)" size="small" fill="clear"
                                      color="medium">
                                      <svg v-if="!this.conversation.messages[index].copyState"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                        class="size-5 text-gray-500">
                                        <path fill-rule="evenodd"
                                          d="M17.663 3.118c.225.015.45.032.673.05C19.876 3.298 21 4.604 21 6.109v9.642a3 3 0 0 1-3 3V16.5c0-5.922-4.576-10.775-10.384-11.217.324-1.132 1.3-2.01 2.548-2.114.224-.019.448-.036.673-.051A3 3 0 0 1 13.5 1.5H15a3 3 0 0 1 2.663 1.618ZM12 4.5A1.5 1.5 0 0 1 13.5 3H15a1.5 1.5 0 0 1 1.5 1.5H12Z"
                                          clip-rule="evenodd" />
                                        <path
                                          d="M3 8.625c0-1.036.84-1.875 1.875-1.875h.375A3.75 3.75 0 0 1 9 10.5v1.875c0 1.036.84 1.875 1.875 1.875h1.875A3.75 3.75 0 0 1 16.5 18v2.625c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625v-12Z" />
                                        <path
                                          d="M10.5 10.5a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963 5.23 5.23 0 0 0-3.434-1.279h-1.875a.375.375 0 0 1-.375-.375V10.5Z" />
                                      </svg>

                                      <svg v-if="this.conversation.messages[index].copyState"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-green-500">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                      </svg>
                                    </button>
                                  </div>
                                </div>

                              </div>


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-else class="w-full h-full center">
                    <div :style="dynamicStyles">
                      <div class="text-center px-6">
                        <svg class="w-12 h-12 text-black dark:text-white mx-auto" aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                          viewBox="0 0 24 24">
                          <path fill-rule="evenodd"
                            d="M5.024 3.783A1 1 0 0 1 6 3h12a1 1 0 0 1 .976.783L20.802 12h-4.244a1.99 1.99 0 0 0-1.824 1.205 2.978 2.978 0 0 1-5.468 0A1.991 1.991 0 0 0 7.442 12H3.198l1.826-8.217ZM3 14v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5h-4.43a4.978 4.978 0 0 1-9.14 0H3Z"
                            clip-rule="evenodd" />
                        </svg>

                        <h2 class="mt-2 text-lg font-medium text-black dark:text-white">Bienvenido
                        </h2>
                        <p class="mt-1 text-sm text-gray-500">Inicia una conversación con tu asistente inteligente
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-full center pt-3">
                  <button
                    class="animation-container w-auto px-4 h-10 bg-black shadow-sombraExterna dark:shadow-sombraExternaDark rounded-full center"
                    :class="this.currentAnimation === 'procesando' || this.currentAnimation === 'hablando' ? 'opacity-70' : 'opacity-100'"
                    @click="toggleRecording" :disabled="isProcessing || isPlaying">
                    <!-- <img :src="currentAnimation" alt="animation gif" /> -->
                    <div class="w-full center gap-2" v-if="this.currentAnimation === 'grabar'">
                      <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                        height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M19 9v3a5.006 5.006 0 0 1-5 5h-4a5.006 5.006 0 0 1-5-5V9m7 9v3m-3 0h6M11 3h2a3 3 0 0 1 3 3v5a3 3 0 0 1-3 3h-2a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3Z" />
                      </svg>

                      <p class="text-white">Pulsa para hablar</p>
                    </div>

                    <div class="w-full center gap-2" v-if="this.currentAnimation === 'grabando'">
                      <svg class="w-6 h-6 text-white animate-pulse" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <rect width="12" height="12" x="6" y="6" stroke="currentColor" stroke-linejoin="round"
                          stroke-width="2" rx="1" />
                      </svg>


                      <p class="text-white">Detener</p>
                    </div>

                    <div class="w-full center gap-2" v-if="this.currentAnimation === 'procesando'">
                      <svg class="w-6 h-6 text-white animate-spin" fill="none" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"></path>
                      </svg>

                      <p class="text-white">Espere un momento</p>
                    </div>

                    <div class="w-full center gap-2" v-if="this.currentAnimation === 'hablando'">
                      <svg class="w-6 h-6 text-white animate-pulse" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M9 6H8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1Zm7 0h-1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1Z" />
                      </svg>

                      <p class="text-white">Hablando</p>
                    </div>
                  </button>
                </div>
              </div>
              <div class="w-full h-auto">
                <div
                  class="relative camera-container h-full max-h-full md:h-full md:max-h-full w-full rounded-xl md:rounded-3xl backdrop-blur-sm bg-black/90 center border border-solid border-neutral-400 dark:border-neutral-700">
                  <video ref="video" class="h-full w-full rounded-xl md:rounded-3xl" autoplay playsinline></video>
                  <canvas ref="canvas" style="display:none;"></canvas>

                  <div class="absolute bottom-2 w-full flex items-center justify-center pt-3 block md:hidden">
                    <button
                      class="animation-container w-auto px-4 h-10 bg-black shadow-xl rounded-full center"
                      @click="toggleRecording" :disabled="isProcessing || isPlaying">
                      <!-- <img :src="currentAnimation" alt="animation gif" /> -->
                      <div class="w-full center gap-2" v-if="this.currentAnimation === 'grabar'">
                        <svg class="size-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                          height="24" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M19 9v3a5.006 5.006 0 0 1-5 5h-4a5.006 5.006 0 0 1-5-5V9m7 9v3m-3 0h6M11 3h2a3 3 0 0 1 3 3v5a3 3 0 0 1-3 3h-2a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3Z" />
                        </svg>

                        <p class="text-white text-sm">Pulsa para hablar</p>
                      </div>

                      <div class="w-full center gap-2" v-if="this.currentAnimation === 'grabando'">
                        <svg class="size-5 text-white animate-pulse" aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                          <rect width="12" height="12" x="6" y="6" stroke="currentColor" stroke-linejoin="round"
                            stroke-width="2" rx="1" />
                        </svg>


                        <p class="text-white text-sm">Detener</p>
                      </div>

                      <div class="w-full center gap-2" v-if="this.currentAnimation === 'procesando'">
                        <svg class="size-5 text-white animate-spin" fill="none" viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                          </circle>
                          <path class="opacity-75"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            fill="currentColor"></path>
                        </svg>

                        <p class="text-white text-sm">Espere un momento</p>
                      </div>

                      <div class="w-full center gap-2" v-if="this.currentAnimation === 'hablando'">
                        <svg class="size-5 text-white animate-pulse" aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M9 6H8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1Zm7 0h-1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1Z" />
                        </svg>

                        <p class="text-white text-sm">Hablando</p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div class="block md:hidden w-full h-[260px] md:h-auto">
                <div class="w-full h-full md:flex md:flex-col">
                  <!-- <div class="flex items-center gap-2">
                    <div class="h-8 w-8 rounded-full bg-blue-400 center">
                      <svg class="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                        height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                          d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
                      </svg>
                    </div>

                    <p class="text-white text-sm">Resultados</p>
                  </div> -->

                  <div class="w-full h-full max-h-[260px] overflow-x-auto" ref="chatContainer">
                    <div v-if="this.conversation.messages && this.conversation.messages.length">
                      <div class="message px-4" :class="{
                        'message-received message-last message-tail':
                          message.role === 'assistant',
                        'message-sent': message.role === 'user',
                      }" v-for="(message, index) in this.conversation.messages" :key="index">
                        <div v-if="message.role === 'user' && message.type === 1" class="messageTop">
                          <div class="w-full py-2 flex items-center justify-end">
                            <div :style="dynamicStyles">
                              <div class="message-text ">
                                <!-- <div style="font-size: large" class="centro" v-if="message.role === 'user'">
                              <b v-if="user !== null">{{ user.name }}</b>
                            </div> -->

                                <div style="height: 10px"></div>
                                <div class="text-black dark:text-white" v-if="message.role === 'user'">
                                  <p class="text-sm text-">{{ message.content }}</p>
                                </div>
                              </div>
                              <div style="font-size: small" class="text-gray-500 w-full text-end">
                                {{ shouldDisplayTimestamp(message.timestamp) }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-if="message.role === 'assistant'">
                          <div class="w-full py-2">
                            <div :style="dynamicStyles">
                              <div class="message-text">

                                <div style="font-size: large" class="" v-if="message.role === 'assistant'">

                                  <div style="font-size: large" class="centro">
                                    <b class="text-black dark:text-white">Elsa</b>
                                  </div>

                                  <div style="height: 10px"></div>
                                  <div class="text-black dark:text-white">
                                    <p class="text-sm">{{ message.content }}</p>
                                  </div>

                                  <div class="mt-2">
                                    <div class="flex items-center gap-4">

                                      <button v-if="this.conversation.messages[index].isComplete &&
                                        (!this.conversation.messages[index].feedback ||
                                          !this.conversation.messages[index].feedback ===
                                          'good' ||
                                          this.conversation.messages[index].feedback === 'bad')
                                      " :disabled="this.conversation.messages[index].feedback === 'bad'" fill="clear"
                                        color="medium" size="small"
                                        @click="this.conversation.messages[index].openBadResponse = true">
                                        <svg v-if="!this.conversation.messages[index].feedback"
                                          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                          class="w-5 h-5 text-gray-500">
                                          <path
                                            d="M15.73 5.5h1.035A7.465 7.465 0 0 1 18 9.625a7.465 7.465 0 0 1-1.235 4.125h-.148c-.806 0-1.534.446-2.031 1.08a9.04 9.04 0 0 1-2.861 2.4c-.723.384-1.35.956-1.653 1.715a4.499 4.499 0 0 0-.322 1.672v.633A.75.75 0 0 1 9 22a2.25 2.25 0 0 1-2.25-2.25c0-1.152.26-2.243.723-3.218.266-.558-.107-1.282-.725-1.282H3.622c-1.026 0-1.945-.694-2.054-1.715A12.137 12.137 0 0 1 1.5 12.25c0-2.848.992-5.464 2.649-7.521C4.537 4.247 5.136 4 5.754 4H9.77a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23ZM21.669 14.023c.536-1.362.831-2.845.831-4.398 0-1.22-.182-2.398-.52-3.507-.26-.85-1.084-1.368-1.973-1.368H19.1c-.445 0-.72.498-.523.898.591 1.2.924 2.55.924 3.977a8.958 8.958 0 0 1-1.302 4.666c-.245.403.028.959.5.959h1.053c.832 0 1.612-.453 1.918-1.227Z" />
                                        </svg>

                                        <svg v-if="this.conversation.messages[index].feedback === 'bad'
                                        " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                          class="w-5 h-5 text-red-400">
                                          <path
                                            d="M15.73 5.25h1.035A7.465 7.465 0 0118 9.375a7.465 7.465 0 01-1.235 4.125h-.148c-.806 0-1.534.446-2.031 1.08a9.04 9.04 0 01-2.861 2.4c-.723.384-1.35.956-1.653 1.715a4.498 4.498 0 00-.322 1.672V21a.75.75 0 01-.75.75 2.25 2.25 0 01-2.25-2.25c0-1.152.26-2.243.723-3.218C7.74 15.724 7.366 15 6.748 15H3.622c-1.026 0-1.945-.694-2.054-1.715A12.134 12.134 0 011.5 12c0-2.848.992-5.464 2.649-7.521.388-.482.987-.729 1.605-.729H9.77a4.5 4.5 0 011.423.23l3.114 1.04a4.5 4.5 0 001.423.23zM21.669 13.773c.536-1.362.831-2.845.831-4.398 0-1.22-.182-2.398-.52-3.507-.26-.85-1.084-1.368-1.973-1.368H19.1c-.445 0-.72.498-.523.898.591 1.2.924 2.55.924 3.977a8.959 8.959 0 01-1.302 4.666c-.245.403.028.959.5.959h1.053c.832 0 1.612-.453 1.918-1.227z" />
                                        </svg>
                                      </button>

                                      <button :disabled="isBotonDesactivado"
                                        v-if="this.conversation.messages[index].isComplete" @click="shareMessage(index)"
                                        size="small" fill="clear" color="medium">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                          class="size-5 text-gray-500">
                                          <path fill-rule="evenodd"
                                            d="M15.75 4.5a3 3 0 1 1 .825 2.066l-8.421 4.679a3.002 3.002 0 0 1 0 1.51l8.421 4.679a3 3 0 1 1-.729 1.31l-8.421-4.678a3 3 0 1 1 0-4.132l8.421-4.679a3 3 0 0 1-.096-.755Z"
                                            clip-rule="evenodd" />
                                        </svg>
                                      </button>

                                      <button v-if="this.conversation.messages[index].isComplete"
                                        @click="Copy(index), openNotification(true, index)" size="small" fill="clear"
                                        color="medium">
                                        <svg v-if="!this.conversation.messages[index].copyState"
                                          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                          class="size-5 text-gray-500">
                                          <path fill-rule="evenodd"
                                            d="M17.663 3.118c.225.015.45.032.673.05C19.876 3.298 21 4.604 21 6.109v9.642a3 3 0 0 1-3 3V16.5c0-5.922-4.576-10.775-10.384-11.217.324-1.132 1.3-2.01 2.548-2.114.224-.019.448-.036.673-.051A3 3 0 0 1 13.5 1.5H15a3 3 0 0 1 2.663 1.618ZM12 4.5A1.5 1.5 0 0 1 13.5 3H15a1.5 1.5 0 0 1 1.5 1.5H12Z"
                                            clip-rule="evenodd" />
                                          <path
                                            d="M3 8.625c0-1.036.84-1.875 1.875-1.875h.375A3.75 3.75 0 0 1 9 10.5v1.875c0 1.036.84 1.875 1.875 1.875h1.875A3.75 3.75 0 0 1 16.5 18v2.625c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625v-12Z" />
                                          <path
                                            d="M10.5 10.5a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963 5.23 5.23 0 0 0-3.434-1.279h-1.875a.375.375 0 0 1-.375-.375V10.5Z" />
                                        </svg>

                                        <svg v-if="this.conversation.messages[index].copyState"
                                          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                          stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-green-300">
                                          <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>

                                </div>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-else class="w-full h-full center">
                      <div :style="dynamicStyles">
                        <div class="text-center px-6">
                          <svg class="w-12 h-12 text-black dark:text-white mx-auto" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                            viewBox="0 0 24 24">
                            <path fill-rule="evenodd"
                              d="M5.024 3.783A1 1 0 0 1 6 3h12a1 1 0 0 1 .976.783L20.802 12h-4.244a1.99 1.99 0 0 0-1.824 1.205 2.978 2.978 0 0 1-5.468 0A1.991 1.991 0 0 0 7.442 12H3.198l1.826-8.217ZM3 14v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5h-4.43a4.978 4.978 0 0 1-9.14 0H3Z"
                              clip-rule="evenodd" />
                          </svg>

                          <h2 class="mt-2 text-lg font-medium text-black dark:text-white">Bienvenido</h2>
                          <p class="mt-1 text-sm text-gray-500">Inicia una conversación con tu asistente
                            inteligente</p>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
          <div class="w-full h-10 max-h-10 text-transparent block md:hidden">.</div>
        </div>
      </div>
    </div>

    <!-- Global notification live region, render this permanently at the end of the document -->
    <div aria-live="assertive" style="width: 100%;"
      class="z-50 pointer-events-none fixed w-full inset-0 flex px-4 py-6 items-start md:p-6">
      <div class="flex w-full flex-col items-center space-y-4 md:items-end">
        <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
        <transition enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 md:translate-y-0 md:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 md:translate-x-0"
          leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
          leave-to-class="opacity-0">
          <div v-if="show"
            class="pointer-events-auto w-full max-w-md overflow-hidden rounded-lg bg-white dark:bg-neutral-700 shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="p-4">
              <div class="flex items-start">
                <div class="flex-shrink-0">
                  <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="text-sm font-medium text-gray-900 dark:text-white">
                    ¡Mensaje copiado satisfactoriamente!
                  </p>
                  <p class="mt-1 text-sm text-gray-500 dark:text-gray-300">
                    Ahora puedes compartirlo donde quieras.
                  </p>
                </div>
                <div class="ml-4 flex flex-shrink-0">
                  <button type="button" @click="closeNotification(false, index)"
                    class="inline-flex rounded-md text-gray-400 focus:outline-none focus:ring-0 focus:ring-indigo-500 focus:ring-offset-0">
                    <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <!-- Global notification live region, render this permanently at the end of the document -->
    <div aria-live="assertive" style="width: 100%;"
      class="z-50 pointer-events-none fixed w-full inset-0 flex px-4 py-6 items-start md:p-6">
      <div class="flex w-full flex-col items-center space-y-4 md:items-end">
        <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
        <transition enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 md:translate-y-0 md:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 md:translate-x-0"
          leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
          leave-to-class="opacity-0">
          <div v-if="showErrorCamara"
            class="pointer-events-auto w-full max-w-md overflow-hidden rounded-lg bg-white dark:bg-neutral-700 shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="p-4">
              <div class="flex items-start">
                <div class="flex-shrink-0">
                  <ExclamationTriangleIcon class="h-6 w-6 text-red-400" aria-hidden="true" />
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="text-sm font-medium text-gray-900 dark:text-white">
                    Ha ocurrido un error
                  </p>
                  <p class="mt-1 text-sm text-gray-500 dark:text-gray-300">
                    {{ this.messageErrorCamara }}
                  </p>
                </div>
                <div class="ml-4 flex flex-shrink-0">
                  <button type="button" @click="closeNotificationErrorCamara(false)"
                    class="inline-flex rounded-md text-gray-400 focus:outline-none focus:ring-0 focus:ring-indigo-500 focus:ring-offset-0">
                    <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";

import io from 'socket.io-client';

import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
/* import { User } from 'lucide-vue-next'; */

export default {
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    CheckCircleIcon,
    ExclamationTriangleIcon,
  },
  data() {

    return {
      api_url: 'https://api-chat.redmasiva.com',

      /* api_url_login: import.meta.env.VITE_API_URL_LOGIN, */

      type: [],
      // variables de modo claro/oscuro ----------------------------------------------------
      thememode: '',
      darkmode: '',

      // Index: null,
      tam: 16,
      // otro
      chats: [],
      cont_chat: 0,
      isAudio: false,
      isMediaEnabled: false,
      isLogged: false,
      token: '',

      user: null,

      conversacionInsertada: null,

      openModalSuscription: false,

      currentAnimation: 'grabar',
      socket: null,
      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      result: null,
      video: null,
      canvas: null,
      isAutoRecordingEnabled: false,
      audioContext: null,
      analyzer: null,
      silenceTimer: null,
      isSpeaking: false,
      isProcessing: false,
      isPlaying: false,

      conversation: {
        messages: []
      },

      show: false,
      badresponse: '',
      openBadResponse: false,
      marcabad: false,
      selectedAuto: false,
      messageErrorCamara: ''
    };
  },
  mounted() {
    this.connectSocket();
    this.startCamera();
  },
  beforeMount() {
    const userJSON = localStorage.getItem("user_data");
    if (userJSON) {
      this.user = JSON.parse(userJSON);
    }

    /* this.$emit('actFuncSubs', true); */

    /* if (!this.user.subscription_id) {
      this.abrirSuscription(true)
    } */

    /* this.checkSubscription(); */

    this.EnviarPadre(0)

    const cachedChats = localStorage.getItem("chats");
    if (cachedChats) {
      this.chats = JSON.parse(cachedChats);
    }

    const cachedToken = localStorage.getItem('token')
    if (cachedToken) {
      this.token = JSON.parse(cachedToken);
    } else {
      this.token = ''
    }

    const size = localStorage.getItem("size");
    if (size) {
      this.tam = JSON.parse(size);
    }

    // Extraer theme de localStorage --------------------------------------------------------------------
    const cachedThemeMode = localStorage.getItem("theme");
    if (cachedThemeMode) {
      this.thememode = cachedThemeMode;
    }
  },
  async created() {
    this.isMediaEnabled = false;

    // Extraer theme de localStorage --------------------------------------------------------------------
    const cachedThemeMode = localStorage.getItem("theme");
    if (cachedThemeMode) {
      this.thememode = cachedThemeMode;
    }

    const cachedToken = localStorage.getItem('token')
    if (cachedToken) {
      this.token = JSON.parse(cachedToken);
    } else {
      this.token = ''
    }

    // extraer de localstorage los datos del usuario logueado
    const isDatax = localStorage.getItem("data_account");
    if (isDatax) {
      this.isLogged = true;
    } else {
      this.isLogged = false;
    }

    // extraer de localstorage los datos del array de mensajes
    const cachedMessages = localStorage.getItem("chatMessages");
    if (cachedMessages) {
      this.messages = JSON.parse(cachedMessages);
    }

    // extraer de localstorage los datos del array de conversaciones
    const cachedChats = localStorage.getItem("chats");
    if (cachedChats) {
      this.chats = JSON.parse(cachedChats);
    }

    // extraer de localstorage los datos del tamaño de fuente
    const size = localStorage.getItem("size");
    if (size) {
      this.tam = JSON.parse(size);
    }
  },
  methods: {
    resetConversation() {
      this.conversation.messages = [];
      this.result = null;
      this.socket.emit('reset_conversation')
    },
    connectSocket() {
      this.socket = io('https://elsa-api.one.ooxen.com');

      this.socket.on('response', (data) => {
        this.result = data;
        this.conversation.messages.push({
          role: 'user',
          content: data.transcription
        });
        this.conversation.messages.push({
          role: 'assistant',
          content: data.thought
        });
        console.log('Received response:', data);
        this.isProcessing = false;
        this.playAudio();
      });

      this.socket.on('vision', (data) => {
        this.result = data;
        console.log('Received vision:', data);
        this.takePhoto(data.text);
      });

      this.$nextTick(() => {
        const contentElement = this.$refs.chatContainer2;
        if (contentElement) {
          contentElement.scrollTo({
            top: contentElement.scrollHeight,
            behavior: 'smooth',
          });
        }
      });

      this.$nextTick(() => {
        const contentElement = this.$refs.chatContainer;
        if (contentElement) {
          contentElement.scrollTo({
            top: contentElement.scrollHeight,
            behavior: 'smooth',
          });
        }
      });
    },
    async toggleRecording() {
      if (this.isProcessing || this.isPlaying) return;

      if (!this.isRecording) {
        await this.startRecording();
      } else {
        this.stopRecording();
      }
    },
    async startRecording() {
      try {
        this.currentAnimation = 'grabando';
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);
        this.audioChunks = [];

        this.mediaRecorder.ondataavailable = (event) => {
          this.audioChunks.push(event.data);
        };

        this.mediaRecorder.onstop = () => {
          const audioBlob = new Blob(this.audioChunks, { type: 'audio/mpeg' });
          const reader = new FileReader();
          reader.readAsDataURL(audioBlob);
          reader.onloadend = () => {
            const base64Audio = reader.result;
            this.socket.emit('process_audio', { audio_url: base64Audio });
            this.isProcessing = true;
          };
        };

        this.mediaRecorder.start();
        this.isRecording = true;
      } catch (error) {
        console.error('Error accessing microphone:', error);
      }
    },
    stopRecording() {
      if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
        this.mediaRecorder.stop();
        this.currentAnimation = 'procesando';
        this.isRecording = false;
      }
    },
    playAudio() {
      if (this.result && this.result.speech.audioStream && !this.isPlaying) {
        this.isPlaying = true;
        this.currentAnimation = 'hablando';
        const audio = new Audio("data:audio/mpeg;base64," + this.result.speech.audioStream);
        audio.onended = () => {
          console.log("Terminó");
          setTimeout(() => {
            this.currentAnimation = 'grabar';
            this.isPlaying = false;
          }, 1000);
        };
        audio.play().catch((e) => {
          console.error("Error playing audio:", e);
          this.currentAnimation = 'grabar';
          this.isPlaying = false;
        });
      }
    },
    async startCamera() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        this.$refs.video.srcObject = stream;
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          console.error('Permiso para usar la cámara fue denegado.');
          this.openNotificationErrorCamara(true, 'Permiso para usar la cámara denegado.')
        } else if (error.name === 'NotFoundError') {
          console.error('No se encontró ningún dispositivo de cámara.');
          this.openNotificationErrorCamara(true, 'No se encontró ningún dispositivo de cámara.')
        } else if (error.name === 'OverconstrainedError') {
          console.error('La configuración de constraints no es compatible con este dispositivo.');
          this.openNotificationErrorCamara(true, 'La configuración no es compatible con este dispositivo.')
        } else {
          console.error('Error accediendo a la cámara:', error);
          this.openNotificationErrorCamara(true, 'Error accediendo a la cámara.')
        }
      }
    },
    takePhoto(text) {
      const context = this.$refs.canvas.getContext('2d');
      this.$refs.canvas.width = this.$refs.video.videoWidth;
      this.$refs.canvas.height = this.$refs.video.videoHeight;
      context.drawImage(this.$refs.video, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height);

      const imageDataUrl = this.$refs.canvas.toDataURL('image/jpeg');
      this.socket.emit('process_image', { image_url: imageDataUrl, text });
    },
    toggleAutoRecording(value) {
      if (this.isProcessing || this.isPlaying) return;

      console.log(value);

      this.isAutoRecordingEnabled = value;
      if (this.isAutoRecordingEnabled === true) {
        this.initAudio();
        console.log('hola');

      } else {
        this.stopAutoRecording();
        console.log('hola2');
      }
    },
    initAudio() {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.analyzer = this.audioContext.createAnalyser();

      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          const source = this.audioContext.createMediaStreamSource(stream);
          source.connect(this.analyzer);
          this.checkAudioLevel();
        })
        .catch((err) => {
          console.error('Error al acceder al micrófono:', err);
        });
    },
    checkAudioLevel() {
      if (this.isAutoRecordingEnabled === false) return;

      const bufferLength = this.analyzer.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      this.analyzer.getByteFrequencyData(dataArray);
      const average = dataArray.reduce((a, b) => a + b) / bufferLength;

      if (average > 10 && !this.isSpeaking && !this.isProcessing && !this.isPlaying) {
        this.startAutoRecording();
      } else if (average <= 10 && this.isSpeaking) {
        this.stopAutoRecording();
      }

      requestAnimationFrame(this.checkAudioLevel);
    },
    startAutoRecording() {
      if (this.isProcessing || this.isPlaying) return;

      this.isSpeaking = true;
      clearTimeout(this.silenceTimer);
      if (!this.isRecording) {
        this.startRecording();
      }
    },
    stopAutoRecording() {
      this.silenceTimer = setTimeout(() => {
        this.isSpeaking = false;
        if (this.isRecording) {
          this.stopRecording();
        }
      }, 1500);
    },
    shouldDisplayTimestamp(timestamp) {
      const messageTime = new Date(timestamp);
      const currentTime = new Date();
      const differenceInHours = (currentTime - messageTime) / (1000 * 60 * 60);

      if (differenceInHours <= 24) {
        return messageTime.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
      } else {
        return "";
      }
    },
    receiveMessage(userMessage, assistantMessage) {
      this.conversation.messages.push({
        id_message: this.conversation.messages.length,
        content: userMessage,
        role: "user",
        type: 1,
        timestamp: new Date(),
        isAudio: this.isAudio,
      });

      localStorage.setItem("nina", JSON.stringify(this.conversation));

      this.conversation.messages.push({
        id_conversacion: this.conversation.id,
        id_message: this.conversation.messages.length,
        isComplete: true,
        content: assistantMessage,
        role: "assistant",
        timestamp: new Date(),
        downloaded: false,
        copyState: false,
        favorite: false,
        feedback: "",
        feedback_content: "",
        lang:
          localStorage.getItem("language") ||
          navigator.language.slice(0, 2) ||
          "en",
        /* type: this.selected.name, */
        referenceActive: false,
        referenceLinks: [],
        imageActive: false,
        images: [],
        videoActive: false,
        videos: [],
        newsActive: false,
        news: []
      });

      /* const indice3 = this.agente[0].chats.findIndex(
        (conversation) => conversation.id === this.conversation.id
      );
      if (indice3 !== -1) {
        this.agente[0].chats[indice3] = this.conversation;
      }

      const indice4 = this.agentes.findIndex(
        (agente) => agente.id === this.agente[0].id
      );
      if (indice4 !== -1) {
        this.agentes[indice4] = this.agente[0];
      } */

      localStorage.setItem("nina", JSON.stringify(this.conversation));

      this.$nextTick(() => {
        const contentElement = this.$refs.chatContainer2;
        if (contentElement) {
          contentElement.scrollTo({
            top: contentElement.scrollHeight,
            behavior: 'smooth',
          });
        }
      });

      this.$nextTick(() => {
        const contentElement = this.$refs.chatContainer;
        if (contentElement) {
          contentElement.scrollTo({
            top: contentElement.scrollHeight,
            behavior: 'smooth',
          });
        }
      });
    },
    abrirSuscription(state) {
      this.openModalSuscription = state;
    },
    EnviarPadre(id) {
      this.conversacionInsertada = id;
      this.$emit('escucharHijo', this.conversacionInsertada)
    },
    EnviarType(type) {
      this.type = type;
    },
    // Función que se ejecutará continuamente
    variableHijo(value) {
      this.EnviarPadre(value);
    },
    typeChat(value) {
      this.selected = value;

    },
    async IrTerminos() {
      this.$router.push('/terminos');
    },
    async openModal(valor) {
      this.open = valor;
    },
    async shareMessage(index) {
      let message = this.conversation.messages[index].content;

      const indice = this.conversation.messages[index].content.indexOf('[r]');
      if (indice !== -1) {
        message = message.replace(/---[^]*$/, '');
      } else {
        message = this.conversation.messages[index].content;
      }

      let url = "https://chat.redmasiva.com";

      if (navigator.share) {
        // Navegador admite la API de Web Share
        navigator
          .share({
            title:
              "Pregunta: " + this.conversation.messages[index - 1].content,
            text: message,
            url: url,
          })
          .then(() => {
            console.log("Contenido compartido con éxito");
          })
          .catch((error) => {
            console.error("Error al compartir:", error);
          });
      } else {
        // El navegador no admite la API de Web Share

        // Construir la URL del enlace "shareapp"
        const shareUrl = `shareapp://sharetext?=${message}`;

        // Redirigir a la URL
        window.location.href = shareUrl;
      }
    },
    async Copy(index) {
      let message = this.conversation.messages[index].content;

      /* const indice = this.conversacion[0].messages[index].content.indexOf('[r]');
      if (indice !== -1) {
        message = message.replace(/---[^]*$/, '');
      } else {
        message = this.conversacion[0].messages[index].content;
      } */

      const shareIntent = document.createElement("textarea");
      shareIntent.value = message;
      document.body.appendChild(shareIntent);
      shareIntent.select();
      document.execCommand("copy");
      document.body.removeChild(shareIntent);
    },
    openNotification(state, index) {
      this.show = state;
      this.conversation.messages[index].copyState = state;

      setTimeout(() => {
        this.closeNotification(false, index);
      }, 3000);
    },
    closeNotification(state, index) {
      this.show = state;
      this.conversation.messages[index].copyState = state;
    },
    openNotificationErrorCamara(state, message) {
      this.showErrorCamara = state;
      this.messageErrorCamara = message;

      setTimeout(() => {
        this.closeNotificationErrorCamara(false);
      }, 3000);
    },
    closeNotificationErrorCamara(state) {
      this.showErrorCamara = state;
      this.messageErrorCamara = '';
    },
    async enviarBadResponse(index) {

      this.conversation.messages[index].openBadResponse = false;

      this.conversation.messages[index].feedback = "bad";
      this.conversation.messages[index].feedback_content = this.badresponse;

      /* const indice = this.chatsGen.findIndex(
        (chat) => chat.id === this.conversacion[0].id
      );
      if (indice !== -1) {
        this.chatsGen[indice] = this.conversacion[0];
      } */

      const payloadFeedback = {
        question: this.conversation.messages[index - 1].content,
        content: this.conversation.messages[index].content,
        feedback: this.conversation.messages[index].feedback,
        feedback_content: this.conversation.messages[index].feedback_content
      };

      this.sendFeedbackToken(payloadFeedback);

      localStorage.setItem("nina", JSON.stringify(this.conversation));
      this.marcabad = true;
    },
    async sendFeedbackToken(payloadFeedback) {
      try {
        const { data } = await axios.post(`${this.api_url_login}/api/feedback`, payloadFeedback, {
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + this.token
          }
        });

        console.log(data);

      } catch (error) {
        if (error.response.status !== 401) {
          console.error("Error inesperado.", error);
          return
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("token_type");
          localStorage.removeItem("user_data");
          localStorage.removeItem("sesionInvitado");
          localStorage.removeItem("plan");
          localStorage.removeItem("trial_days");

          this.isLogged = false;

          const userJSON = localStorage.getItem("user_data");
          if (userJSON) {
            this.user = JSON.parse(userJSON);
          } else {
            this.user = null;
          }

          this.$router.push("/login");
        }
      }
    },
  }
}
</script>

<style scoped>
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
